var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChanged },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _vm.store.getters.are_todo_screens_enabled
                ? _c("el-tab-pane", {
                    attrs: { label: _vm.todosTitle, name: "_todo" },
                  })
                : _vm._e(),
              _vm.store.getters.is_gcal_enabled
                ? _c("el-tab-pane", {
                    attrs: { label: "Events", name: "_events" },
                  })
                : _vm._e(),
              _c("el-tab-pane", { attrs: { label: "Team", name: "_team" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.activeTab == "_todo"
            ? _c("HomeTab_todo", { key: "todo" })
            : _vm.activeTab == "_events"
            ? _c("HomeTab_events", { key: "events" })
            : _vm.activeTab == "_team"
            ? _c("HomeTab_team", { key: "team" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }