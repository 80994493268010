var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "content-block",
        { staticStyle: { "margin-right": "5px" } },
        [
          _c("div", { staticClass: "title" }, [_vm._v("Today")]),
          _vm.today_calendar.length > 0
            ? _vm._l(_vm.today_calendar, function (event) {
                return _c("calendar-item-card", {
                  key: event.id,
                  staticClass: "event",
                  attrs: { event: event },
                })
              })
            : _c("div", { staticClass: "placeholder" }, [
                _vm._v(" No scheduled events "),
              ]),
        ],
        2
      ),
      _c(
        "content-block",
        { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
        [
          _c("div", { staticClass: "title" }, [_vm._v("Tomorrow")]),
          _vm.tomorrow_calendar.length > 0
            ? _vm._l(_vm.tomorrow_calendar, function (event) {
                return _c("calendar-item-card", {
                  key: event.id,
                  staticClass: "event",
                  attrs: { event: event },
                })
              })
            : _c("div", { staticClass: "placeholder" }, [
                _vm._v(" No scheduled events "),
              ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }