var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("user-day-tracker", {
        attrs: { user: _vm.current_user, theme: "dark" },
      }),
      _vm.user_is_admin
        ? _vm._l(_vm.other_users, function (user) {
            return _c("user-day-tracker", {
              key: user.id + "_day-tracker",
              attrs: { user: user },
            })
          })
        : _vm._l(_vm.other_users, function (user) {
            return _c("user-block", {
              key: user.id + "_user-tracker",
              attrs: { user: user },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }