var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event", class: { ignored: _vm.ignored } }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.event.content) + " "),
      ]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c("project-dropdown", {
            model: {
              value: _vm.selectedProjectId,
              callback: function ($$v) {
                _vm.selectedProjectId = $$v
              },
              expression: "selectedProjectId",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("div", { staticClass: "date" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("dateformat")(_vm.event.start.toDate(), "DD/MM/YY")
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "time" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("dateformat")(_vm.event.start.toDate(), "HH:mm")) +
              " - " +
              _vm._s(_vm._f("dateformat")(_vm.event.end.toDate(), "HH:mm")) +
              " "
          ),
        ]),
        _c(
          "el-dropdown",
          {
            attrs: {
              trigger: "click",
              placement: "bottom-end",
              "popper-class": "popper-over-modal",
            },
            on: { command: _vm.runCommand },
          },
          [
            _c("div", { staticClass: "down-icon" }, [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  attrs: { focusable: "false", viewBox: "0 0 24 24" },
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z",
                    },
                  }),
                ]
              ),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                !_vm.ignored
                  ? [
                      _c("el-dropdown-item", { attrs: { command: "ignore" } }, [
                        _vm._v(" Ignore event "),
                      ]),
                    ]
                  : [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "unignore" } },
                        [_vm._v(" Unignore event ")]
                      ),
                      _vm.event.recurringEventId && _vm.ignored_recurring
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "unignoreRecurring" } },
                            [_vm._v(" Unignore recurring ")]
                          )
                        : _vm._e(),
                    ],
                _vm.event.recurringEventId && !_vm.ignored_recurring
                  ? _c(
                      "el-dropdown-item",
                      { attrs: { command: "ignoreRecurring" } },
                      [_vm._v(" Ignore recurring ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }